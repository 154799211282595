.MuiInputBase-root:before {
  display: none !important;
}

.MuiInputBase-root {
  overflow: hidden !important;
}

.MuiButton-icon {
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .MuiIconButton-edgeEnd {
  color: #999999 !important ;
}

.MuiInput-underline:before {
  border-bottom: 1px solid var(--translucentHard) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #999 !important;
}
.MuiFormLabel-root {
  color: #999 !important;
}





.MuiInputBase-input {
  overflow: hidden !important;
} */

.MuiSlider-root {
  color: var(--color) !important;
}

.MuiSlider-thumb:hover {
  box-shadow: 0px 0px 0px 8px rgba(210, 118, 25, 0.16) !important;
}

.MuiSlider-thumb.Mui-focusVisible {
  box-shadow: 0px 0px 0px 8px rgba(210, 118, 25, 0.16) !important;
}

.MuiDataGrid-row {
  background-color: rgb(255 255 255 / 91%) !important;
}
